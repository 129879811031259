import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./styles.scss";

// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import { RichTextSerializers } from "../../utils/richTextSerializers";

const TextBlocksGrid = props => {

    const { heading, _rawBody, items, bgColor } = props;
    return (
        <>
            <section className={`rx-grid--text rx-theme-${bgColor?.title?.toLowerCase() || "teal"}`}>
                <Container fluid>
                    <Row className={"grid-row"}>
                        <Col md={12}>
                            <h2>{heading}</h2>
                            <div>
                                <BlockContent blocks={_rawBody} serializers={RichTextSerializers()} />
                            </div>
                        </Col>
                        {items && items.map((item, index) => {
                            const { _rawTextBody } = item;
                            return (
                                <React.Fragment key={index}>
                                    <Col xs={12} md={6}>
                                        <div className="rx-grid--text-content">
                                            <div className="rx-grid--text-content--inner">
                                                <BlockContent blocks={_rawTextBody} serializers={RichTextSerializers()} />
                                            </div>
                                        </div>
                                    </Col>
                                </React.Fragment>
                            )
                        })}
                    </Row>
                </Container>
            </section>
        </>
    );
};
export default TextBlocksGrid;
